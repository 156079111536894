const showLoginPopup = () => {
  const url = App.order.login_path
    ? App.order.login_path.replace(/returnUrl.*$/, 'returnUrl=/login/go/back')
    : 'about:blank';

  window.open(
    url,
    'login',
    'alwaysRaised=1,menubar=0,toolbar=0,location=0,personalbar=0,status=0,width=800,height=600'
  );
};

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', (e) => {
    const target = e.target;
    const dataLogin = target.getAttribute('data-login');
    
    if (dataLogin !== null) {
      e.preventDefault();
      showLoginPopup();
    }
  });

  window.addEventListener('showLogin', () => showLoginPopup());
});

export default showLoginPopup;
